<template lang="pug">
    .content-bdi
        .content-bdi__header
            HeadOrganization
        .content-bdi__body
            .block(style="padding-right:350px")
                .flex.items-center.justify-between.mb-8
                    h2.subheading(style="padding-bottom:0px") 1.561 berita ditemukan
                    //- b-button(text="Tambah Aktivitas" text_icon_left="file-plus" kind="primary" @click.native="openActivity = !openActivity")
                    .box-calendar.mr-4
                        b-icon(name="calendar")
                        date-range-picker(
                            v-if="dateRange.startDate && dateRange.endDate"
                            ref="picker"
                            v-model="dateRange"
                            @update="pickerUpdate($event)"
                            :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy', applyLabel: 'Terapkan', cancelLabel: 'Batalkan', }"
                            :date-format="dateFormatPicker"
                            opens="left"
                            drops="up"
                            :ranges="default_ranges"
                            autoApply=false
                            @toggle="showCalendar()"
                        )
                            template(slot="input" slot-scope="picker" style="min-width: 350px;") {{ picker.startDate | date }} - {{ picker.endDate | date }}
                .box-media
                    .label-alert.items-center.rounded.mb-2
                        .label-alert__cover
                            b-icon(name="calendar" size="small")
                        .label-alert__info
                            p.text-xs Data Berita diambil dari rentang waktu <strong> 15 April 2021 Pukul 16:04 - 01 Maret 2022 Pukul 19:03  </strong>
                    article.media(v-for="item in berita")
                        .media__type
                            b-icon(name="link")
                        //- .media__type
                            template(v-if="item.type === 'media'")
                                b-icon(name="link")
                            template(v-else)
                                b-icon(name="file")

                        .media__cover
                            a(:href="item.url" target='_blank')
                                figure.media__cover
                                    img(v-bind:src="item.cover")
                        .media__content
                            .mb-2
                                .label.label--gray.text-xs.font-bold.mb-1 {{ item.source}}
                                a.link-media.mb-2.block(:href="item.url" target='_blank' v-html="item.title")
                                //- p.text-gray-500.mb-2 {{ item.description }}
                                p.mb-2(v-html="trimmedStr(item.content)")
                                span.text-xs.text-gray-500 {{ item.publishedAt }}
                    
                ul.p-4.pagination
                    li.btn.pagination-btn: b-button(kind="tertiary" icon_only="chevron-left")
                    li.btn.pagination-btn: b-button(kind="primary" text="1")
                    li.btn.pagination-btn: b-button(kind="tertiary" text="2")
                    li.btn.pagination-btn: b-button(kind="tertiary" text="3")
                    li.btn.pagination-btn: b-button(kind="tertiary" text="4")
                    li.btn.pagination-btn: b-button(kind="tertiary" text="5")
                    li.btn.pagination-btn: b-button(kind="tertiary" text="...")
                    li.btn.pagination-btn: b-button(kind="tertiary" text="187")
                    li.btn.pagination-btn: b-button(kind="tertiary" icon_only="chevron-right")
                        
</template>

<script>
import HeadOrganization from '@/pages/organization/HeadOrganization';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
     components: {
        HeadOrganization,
        DateRangePicker
    },

    data() {
        return {
             dateRange: {
                startDate: moment().subtract(1, 'month').toDate(),
                endDate: moment().toDate(),
            },
            default_ranges: {
                'Hari ini': [moment().toDate(), moment().toDate()],
                'Kemarin': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                '7 hari lalu': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Minggu lalu': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            berita: [
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/10154769/pexels-photo-10154769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Unggahan Calon Konsumen Mengaku Kena Tipu di Diler Honda MT Haryono',
                    content: 'Berhubung pertemuan dilakukan di diler pada hari Minggu dan area kasir tutup, Ruhan menyarankan kepada Yunita untuk mentransfer uang senilai Rp 10 juta sebagai booking fee ke rekening Dedi yang dikenalkannya sebagai supervisornya di diler...',
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/10049570/pexels-photo-10049570.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Pentingnya Komunitas untuk Seorang Pemrogram',
                    content: 'Manfaat mengikuti komunitas untuk seorang pemrogram — Sebagai seorang pemrogram kebanyakan waktu kita habiskan di depan layar komputer, entah itu untuk menulis kode program, mencari bug pada...',
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/7846313/pexels-photo-7846313.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Slow Relationships, Sustainable Business',
                    content: '50 easy ways to re-connect for defensive entrepreneurship and post-pandemic socializing — Where did my most recent client work come from? Let’s see: Referral from the husband of an author I once published. Referr...',
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/10828146/pexels-photo-10828146.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Bad UX Design — 13 Worst UX Design Fails to Avoid in Your SaaS',
                    content: "From Amazon’s navigation bar to Whatsapp’s deleted messages: bad UX design is something that we’re all familiar with as consumers. From a SaaS...",
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://miro.medium.com/fit/c/224/224/1*B72FbDYP--dZl3Z-NEQB0Q.jpeg',
                    source: 'kompas.com',
                    title: 'Referensi Layanan Web yang Menyediakan Asset Gambar dan Icon SVG Gratis',
                    content: "From Amazon’s navigation bar to Whatsapp’s deleted messages: bad UX design is something that we’re all familiar with as consumers. From a SaaS...",
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/11198483/pexels-photo-11198483.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Product Interview: (Hypothetical) Figma is losing customers at 6–7% weekly. Figure out the reason & prioritize solutions.',
                    content: "Minggu lalu gw ada satu sesi ADPlist dengan seorang admin yang sedang berusaha untuk switch carrier ke bidang product design. Pertanyaannya “Ada tips and trick ga untuk saya bisa menjadi designer?” Biasanya, dihadapkan dengan pertanyaan2 mentoring, gw dengan cepat akan bisa",
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/10049570/pexels-photo-10049570.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Pentingnya Komunitas untuk Seorang Pemrogram',
                    content: 'Manfaat mengikuti komunitas untuk seorang pemrogram — Sebagai seorang pemrogram kebanyakan waktu kita habiskan di depan layar komputer, entah itu untuk menulis kode program, mencari bug pada...',
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/7846313/pexels-photo-7846313.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Slow Relationships, Sustainable Business',
                    content: '50 easy ways to re-connect for defensive entrepreneurship and post-pandemic socializing — Where did my most recent client work come from? Let’s see: Referral from the husband of an author I once published. Referr...',
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://miro.medium.com/fit/c/224/224/1*B72FbDYP--dZl3Z-NEQB0Q.jpeg',
                    source: 'kompas.com',
                    title: 'Referensi Layanan Web yang Menyediakan Asset Gambar dan Icon SVG Gratis',
                    content: "From Amazon’s navigation bar to Whatsapp’s deleted messages: bad UX design is something that we’re all familiar with as consumers. From a SaaS...",
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
                {
                    url: 'https://www.google.com/',
                    cover: 'https://images.pexels.com/photos/10828146/pexels-photo-10828146.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                    source: 'kompas.com',
                    title: 'Bad UX Design — 13 Worst UX Design Fails to Avoid in Your SaaS',
                    content: "From Amazon’s navigation bar to Whatsapp’s deleted messages: bad UX design is something that we’re all familiar with as consumers. From a SaaS...",
                    publishedAt: 'Selasa, 01 Maret 2022'
                },
            ]
        }
    },
    computed: { 
        filterStartDate() {
            return moment(this.dateRange.startDate).format('YYYY-MM-DD');
        },
        filterEndDate() {
            return moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
    },

    filters: {
        date: function(date) {
            return moment(date).locale('id').format('DD MMM YYYY');
        },
    },
}
</script>